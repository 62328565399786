import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { useFormFields } from '../libs/hooksLib';
import './Login.css';
//import { API } from 'aws-amplify';
//import config from '../config';
import PageHeader from '../components/PageHeader';

export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    userName: '',
    password: '',
  });

/*  
  function getQBFiles() {
    //    alert(config.environment.APP_PREFIX);
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
        user_id: fields.userName,
      },
    });
  }
*/

  function validateForm() {
    return fields.userName.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      var user = await Auth.signIn(fields.userName, fields.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        props.setIsAuthenticated(true);
        sessionStorage['last_activity_time'] = new Date().getTime();
        props.history.push({
          pathname: '/changepassword',
          userName: fields.userName,
          oldPassword: fields.password,
        });
        //        props.history.push('changepassword');
      } else {
        props.setIsAuthenticated(true);
        sessionStorage['last_activity_time'] = new Date().getTime();
//        var data = await getQBFiles();
//        if (data.files.length > 0) {
//          props.history.push('/upcomingdeliveries');
//        } else {
          props.history.push('/customers');
//        }
      }
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className='Login'>
      <PageHeader title='Login' info='Please enter your credentials to log in to the system.' />
      <div style={{ padding: '30px' }} className='widget'>
        <form onSubmit={handleSubmit}>
          <FormGroup controlId='userName' bsSize='large'>
            <ControlLabel>User Name</ControlLabel>
            <FormControl
              autoFocus
              type='text'
              value={fields.userName}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup style={{ marginBottom: '0px' }} controlId='password' bsSize='large'>
            <ControlLabel>Password</ControlLabel>
            <FormControl type='password' value={fields.password} onChange={handleFieldChange} />
          </FormGroup>

          <LinkContainer style={{ cursor: 'pointer', padding: '0px' }} to='/forgotpassword'>
            <FormControl.Static bsSize='sm'>Forgot password?</FormControl.Static>
          </LinkContainer>

          <LoaderButton
            block
            type='submit'
            bsSize='large'
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login
          </LoaderButton>
        </form>
      </div>

      <LinkContainer style={{cursor:'pointer', marginTop:'10px', color:'blue', marginBottom: '0px'}} to="/privacypolicy">
        <FormControl.Static>Privacy Policy</FormControl.Static>
      </LinkContainer>

    </div>
  );
}
