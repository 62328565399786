import React from "react";
import PageHeader from "../components/PageHeader"

export default function LoggedOut(props) {



  return (
    <div className="LoggedOut">
      <PageHeader title="Logged Out" info={props.location.error}/>

    </div>
  );
}
