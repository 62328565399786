//import Signup from './containers/Signup';
import Login from './containers/Login';
import ChangePassword from './containers/ChangePassword';
import ForgotPassword from './containers/ForgotPassword';
import ForgotPasswordConfirm from './containers/ForgotPasswordConfirm';

import config from './config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import LoggedOut from './containers/LoggedOut';
import AuditReportCriteria from './containers/AuditReportCriteria';
import AuditReport from './containers/AuditReport';
import ActivityReportCriteria from './containers/ActivityReportCriteria';
import ActivityReport from './containers/ActivityReport';
//import ConnectAccountingSystem from './containers/ConnectAccountingSystem';
//import ConnectToQuickbooksResult from './containers/ConnectToQuickbooksResult';
//import ConnectToSageResult from './containers/ConnectToSageResult';
//import BankAccountSetup from './containers/BankAccountSetup';
//import UpcomingDeliveries from './containers/UpcomingDeliveries';
//import RealmSettings from './containers/RealmSettings';
//import FileHistory from './containers/FileHistory';
//import GetCheckData from './containers/GetCheckData';
//import SageCompanyFileList from './containers/SageCompanyFileList';

import Customers from './containers/Customers';
import AddCustomer from './containers/AddCustomer';
import EditCustomer from './containers/EditCustomer';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import PrivacyPolicy from './containers/PrivacyPolicy';

export default function Routes({ appProps }) {
  if (config.bank.SSO === 'true') {
    return (
      <Switch>
        <UnauthenticatedRoute path='/' exact component={Home} appProps={appProps} />
        <AuthenticatedRoute
          path='/customers'
          exact
          component={Customers}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/addcustomer'
          exact
          component={AddCustomer}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/editcustomer'
          exact
          component={EditCustomer}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/auditreportcriteria'
          exact
          component={AuditReportCriteria}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/auditreport'
          exact
          component={AuditReport}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/activityreportcriteria'
          exact
          component={ActivityReportCriteria}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/activityreport'
          exact
          component={ActivityReport}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/privacypolicy'
          exact
          component={PrivacyPolicy}
          appProps={appProps}
        />
        <UnauthenticatedRoute path='/loggedout' exact component={LoggedOut} appProps={appProps} />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
        {/* <UnauthenticatedRoute path="/*" component={Login} appProps={appProps} /> */}
      </Switch>
    );
  } else {
    return (
      <Switch>
        <UnauthenticatedRoute path='/' exact component={Home} appProps={appProps} />
        <UnauthenticatedRoute path='/login' exact component={Login} appProps={appProps} />
        <UnauthenticatedRoute
          path='/privacypolicy'
          exact
          component={PrivacyPolicy}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/customers'
          exact
          component={Customers}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/addcustomer'
          exact
          component={AddCustomer}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/editcustomer'
          exact
          component={EditCustomer}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/auditreportcriteria'
          exact
          component={AuditReportCriteria}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/auditreport'
          exact
          component={AuditReport}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/activityreportcriteria'
          exact
          component={ActivityReportCriteria}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/activityreport'
          exact
          component={ActivityReport}
          appProps={appProps}
        />
        <AuthenticatedRoute
          path='/changepassword'
          exact
          component={ChangePassword}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/forgotpassword'
          exact
          component={ForgotPassword}
          appProps={appProps}
        />
        <UnauthenticatedRoute
          path='/forgotpasswordconfirm'
          exact
          component={ForgotPasswordConfirm}
          appProps={appProps}
        />       
        {/* Finally, catch all unmatched routes */}
        <UnauthenticatedRoute path='/*' component={Login} appProps={appProps} />
        {/* <Route component={NotFound} /> */}
      </Switch>
    );
  }
}
