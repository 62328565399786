import { API } from 'aws-amplify';
import React  from 'react';
import { Button } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ConfirmDialog from './ConfirmDialog';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './AuditReport.css';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-datepicker/dist/react-datepicker.css";

class AuditReport extends TimeoutComponent {

  constructor(props) {
    super(props);
    
    this.state = {
      auditRecords: [],
      begin_date: this.props.location.report_criteria.begin_date,
      end_date: this.props.location.report_criteria.end_date,
    };

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // EVENT HANDLERS
    this.handleClick_cancelButton = this.handleClick_cancelButton.bind(this);
  }

  getDateStr(d)
  {
    var date = new Date(d);
    var dateStr = "";

    let dd = date.getDate();

    let mm = date.getMonth()+1; 
    const yyyy = date.getFullYear();
    if(dd<10) 
    {
        dd=`0${dd}`;
    } 

    if(mm<10) 
    {
        mm=`0${mm}`;
    } 
    dateStr = `${yyyy}-${mm}-${dd}`;

    return dateStr;
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);

      var reportData = await this.getReport();

      if (reportData.error)
      {
        throw new Error(reportData.error);
      }
      this.setState({auditRecords: reportData.records});    
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      if (this.refs.loadingDialog)
      {
        this.refs.loadingDialog.show(false);
      }
    }
  }

  getReport() {
    var beginStr = "";
    var endStr = "";

    beginStr = this.getDateStr(this.state.begin_date);
    endStr = this.getDateStr(this.state.end_date);
    
    return API.get('cloudautocheckissues', '/sessionData', {
      queryStringParameters: {
        user_id: this.props.location.report_criteria.user_id,
        begin_date: beginStr,
        end_date: endStr,
        timezone: this.timezone,
      },
    });

  }

  handleClick_cancelButton() {
    this.props.history.push('/customers');
  }

  // Description column set to monospace and 12px for formatting. Should look into better solution.
  render() {

    const columns = [{
      dataField: 'function',
      text: 'Function',
      sort: true
    }, {
      dataField: 'desc',
      text: 'Description',      
      formatter: (cell, row) => {
        var oldStr = row.desc;
        var newStr = oldStr.replace("\n", "<br/>");
        console.log(newStr);
        return <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', fontFamily: 'monospace', fontSize:'12px' }}>{`${row.desc}`}</span>;
      }
    }, {
      dataField: 'event_date_time',
      text: 'Date/Time',
      sort: true,
      formatter: (cell, row) => {
        console.log(row);
        var newStr = new Date(row.event_date_time).toLocaleString('en-US', {timeZone: this.timezone});
        return <span style={{ whiteSpace: 'pre-wrap' }}>{`${newStr}`}</span>;
      }

    }];
  
    var infoText = 'Date/Time is displayed in the following Time Zone: ' + this.timezone;
    
    return (
      <div className='AuditReport'>
        <PageHeader
          title='Audit Report'
          info= { infoText }
        />

        <Button
          style={{ marginBottom: '20px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Close
        </Button>

        <div style={{ padding: '20px'}} className='widget'>          
          <div style={{ width: '100%', height:'100%'}}>            
            <BootstrapTable          
              keyField="row_number"
              data={ this.state.auditRecords }
              columns={ columns }
              sort={ { dataField: 'event_date_time', order: 'asc' } }
            />
          </div>
        </div>

        <Button
          style={{ marginTop: '20px', marginRight: '15px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Close
        </Button>

        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default AuditReport;
