import React, { useEffect } from 'react';
import '../App.css';
import '../css/branding.css';
import AWS from 'aws-sdk';
import PageHeader from '../components/PageHeader';
import { API, Auth } from 'aws-amplify';
import config from '../config';
import queryString from 'query-string';

function Home(props) {
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    console.log('Home onLoad()');

    try {
      var values = queryString.parse(window.location.search);

      console.log('values = ');
      console.log(values);

      if ('identity' in values) {
        sessionStorage['awsIdentity'] = values.identity;
        sessionStorage['awsToken'] = values.token;

        AWS.config.region = config.cognito.REGION;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
          IdentityId: values.identity,
          Logins: {
            'cognito-identity.amazonaws.com': values.token,
          },
        });
        AWS.config.credentials.refresh(async function (err) {
          if (err) {
            console.log(err);
            props.history.push({ pathname: '/loggedout', error: err.message });
          } else {
            sessionStorage['last_activity_time'] = new Date().getTime();

            var data = await getQBFileList();
            console.log(data);
            if (data.files.length > 0) {
              console.log('/upcomingdeliveries');
              props.history.push('/upcomingdeliveries');
            } else {
              console.log('/connectaccountingsystem');
              props.history.push('/connectaccountingsystem');
            }
          }
        });
      } else if ('state' in values && values.state === 'sage') {
        console.log('/sagecompanyfilelist');
        if (config.bank.SSO === 'true') {
          //        console.log("awsIdentity");
          //        console.log(sessionStorage['awsIdentity']);
          //        console.log("awsToken");
          //        console.log(sessionStorage['awsToken']);

          AWS.config.region = config.cognito.REGION;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
            IdentityId: sessionStorage['awsIdentity'],
            Logins: {
              'cognito-identity.amazonaws.com': sessionStorage['awsToken'],
            },
          });

          AWS.config.credentials.refresh(async function (err) {
            console.log('AWS.config.credentials.refresh');
            if (err) {
              console.log(err);
              props.history.push({ pathname: '/loggedout', error: err.message });
            } else {
              sessionStorage['last_activity_time'] = new Date().getTime();

              props.history.push({
                pathname: '/sagecompanyfilelist',
                state: { sageprops: values },
              });
            }
          });
        } else {
          sessionStorage['last_activity_time'] = new Date().getTime();

          //SRR - TEST
          //          props.setIsAuthenticated(true);
          await Auth.currentSession();
          props.setIsAuthenticated(true);

          props.history.push({ pathname: '/sagecompanyfilelist', state: { sageprops: values } });
        }
      } else if ('realmId' in values || 'error' in values) {
        console.log('/connecttoquickbooksresult');
        if (config.bank.SSO === 'true') {
          //        console.log("awsIdentity");
          //        console.log(sessionStorage['awsIdentity']);
          //        console.log("awsToken");
          //        console.log(sessionStorage['awsToken']);

          AWS.config.region = config.cognito.REGION;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
            IdentityId: sessionStorage['awsIdentity'],
            Logins: {
              'cognito-identity.amazonaws.com': sessionStorage['awsToken'],
            },
          });

          AWS.config.credentials.refresh(async function (err) {
            console.log('AWS.config.credentials.refresh');
            if (err) {
              console.log(err);
              props.history.push({ pathname: '/loggedout', error: err.message });
            } else {
              sessionStorage['last_activity_time'] = new Date().getTime();

              props.history.push({
                pathname: '/connecttoquickbooksresult',
                state: { qbprops: values },
              });
            }
          });
        } else {
          sessionStorage['last_activity_time'] = new Date().getTime();

          //SRR - TEST
          //          props.setIsAuthenticated(true);
          await Auth.currentSession();
          props.setIsAuthenticated(true);

          props.history.push({
            pathname: '/connecttoquickbooksresult',
            state: { qbprops: values },
          });
        }
      }
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  function getQBFileList() {
    console.log('getQBFileList()');
    return API.get('cloudautocheckissues', '/getQBFileList', {
      queryStringParameters: {
        app_prefix: config.environment.APP_PREFIX,
      },
    });
  }

  return (
    <div>
      <PageHeader title='' info='' />
    </div>
  );
}

export default Home;
