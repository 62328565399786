import { API } from 'aws-amplify';
import React  from 'react';
import { Button, Table } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ConfirmDialog from './ConfirmDialog';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './AuditReport.css';
import BootstrapTable from 'react-bootstrap-table-next';
import "react-datepicker/dist/react-datepicker.css";

class ActivityReport extends TimeoutComponent {

  constructor(props) {
    super(props);

    var dataRetrieved = false;

    this.state = {
      reportData: null,
      month: this.props.location.report_criteria.month,
      year: this.props.location.report_criteria.year,
    };
    
    this.today = new Date().toLocaleString();

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // EVENT HANDLERS
    this.handleClick_closeButton = this.handleClick_closeButton.bind(this);
  }

  getMonthStr()
  {
    var str = "";

    if (this.state.month == "01")
      str = "JAN";
    else if (this.state.month == "02")
      str = "FEB";
    else if (this.state.month == "03")
      str = "MAR";
    else if (this.state.month == "04")
      str = "APR";
    else if (this.state.month == "05")
      str = "MAY";
    else if (this.state.month == "06")
      str = "JUN";
    else if (this.state.month == "07")
      str = "JUL";
    else if (this.state.month == "08")
      str = "AUG";
    else if (this.state.month == "09")
      str = "SEP";
    else if (this.state.month == "10")
      str = "OCT";
    else if (this.state.month == "11")
      str = "NOV";
    else if (this.state.month == "12")
      str = "DEC";
  
    return str;
  }

  async componentDidMount() {
    
    try {
      this.refs.loadingDialog.show(true);

      var result = await this.getReport();
      this.setState({reportData: result});

      if (this.state.reportData.error)
      {
        throw new Error(this.state.reportData.error);
      }
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      if (this.refs.loadingDialog)
      {
        this.refs.loadingDialog.show(false);
      }
    }    
  }

  getReport() {
    return API.get('cloudautocheckissues', '/activityData', {
      queryStringParameters: {
        month: this.props.location.report_criteria.month + this.props.location.report_criteria.year,
      },
    });
  }

  handleClick_closeButton() {
    this.props.history.push('/customers');
  }

  // Description column set to monospace and 12px for formatting. Should look into better solution.
  render() {

    var infoText = '';
    
    return (
      <div className='AuditReport'>
        <PageHeader
          title='Activity Report'
          info= { infoText }
        />

        <Button
          style={{ marginBottom: '20px' }}
          onClick={() => {
            this.handleClick_closeButton();
          }}
          alt='...'
        >
          Close
        </Button>

        { this.state.reportData != null &&
          <div style={{ padding: '20px'}} className='widget'>          
            <div style={{ width: '100%', height:'100%'}}>            

              <h4>
              File Activity Report
              </h4>

              <p>{this.today}</p>

              <b>Month:&nbsp;</b>{this.getMonthStr()} {this.state.year}<br/>
        
              <h4>
              Summary:
              </h4>

              <b>Total Active Users:&nbsp;</b>{this.state.reportData.active_users}<br/>
              <b>Total Active Connected Companies:&nbsp;</b>{this.state.reportData.active_companies}<br/>     
              <b>Total Active Accounts:&nbsp;</b>{this.state.reportData.active_accounts}<br/>
              <b>Total # of Files Sent:&nbsp;</b>{this.state.reportData.num_files_sent}<br/>

              <h4>
              Detail:
              </h4>

              {this.state.reportData.users.map((user) => (
                <Table borderless>    
                  <tr key={user.user_name}>
                    <td>
                      <b>User:&nbsp;</b>{user.user_name}
                    </td>
                  </tr>
                  <tr>
                  {user.companies.map((company) => (
                    <Table borderless>
                        <tr>
                          <td style={{paddingLeft:'50px'}}>
                            <b>Connected Company:&nbsp;</b>{company.name}
                          </td>
                        </tr>
                        <tr>
                          {company.accounts.map((acct) => (
                            <Table borderless>
                                <tr>
                                  <td style={{paddingLeft:'100px'}}>
                                    <b>Account:&nbsp;</b>{acct.name}
                                  </td>
                                  <td>
                                    <b># of Files Sent:&nbsp;</b>{acct.num_files_sent}
                                  </td>
                                </tr>
                            </Table>                          
                          ))}
                        </tr>
                    </Table>
                  ))}
                  </tr>
                </Table>
              ))}

            </div>
          </div>
        }

        { this.state.reportData != null &&
          <Button
            style={{ marginTop: '20px', marginRight: '15px' }}
            onClick={() => {
              this.handleClick_closeButton();
            }}
            alt='...'
          >
            Close
          </Button>
        }

        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default ActivityReport;
