import React, { useState }  from 'react';
import { Button,ControlLabel,Form,FormGroup,FormControl } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './AuditReportCriteria.css';
import "react-datepicker/dist/react-datepicker.css";

class ActivityReportCriteria extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.state = {
      period: this.getPeriodValue(new Date()),
    };

    this.dates = [];
    this.periods = [];
    this.createPeriods();

    this.handleChange_period = this.handleChange_period.bind(this);

    // EVENT HANDLERS
    // onClick
    this.handleClick_cancelButton = this.handleClick_cancelButton.bind(this);
    this.handleClick_reportButton = this.handleClick_reportButton.bind(this);
  }

  createPeriods()
  {
    for (var i = 0; i < 6; i++ )
    {
      var date = new Date();
      date.setMonth(date.getMonth() - i);  
      
      this.dates.push(date);
    } 
  }

  getPeriodValue(date)
  {
    let mm = date.getMonth()+1; 
    if(mm<10) 
    {
        mm=`0${mm}`;
    } 

    var periodValue = mm + date.getFullYear();

    return periodValue;
  }

  getPeriodDisplayStr(date)
  {
    var periodDisplayStr = date.toLocaleString('en-us',{month:'short'}).toUpperCase() + " " + date.getFullYear();
    return periodDisplayStr;
  }

  async componentDidMount() {
    try {
/*
      this.forceUpdate();
*/
      this.refs.loadingDialog.show(true);
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  handleChange_period(e) {
    debugger;
    this.setState({ period: e.target.value });
  }

  handleClick_cancelButton() {
    this.props.history.push('/customers');
  }

  async handleClick_reportButton()
  {
    try
    {
      this.props.history.push({pathname: "/activityreport", report_criteria: { "month": this.state.period.substring(0,2), "year": this.state.period.substring(2)} });      
    }
    catch (e)
    {
      this.refs.errorDialog.displayError('Error', e.message);
    }
    finally
    {
    }
  }

  render() {
    return (
      <div className='ActivityReportCriteria'>
        <PageHeader
          title='Activity Report Criteria'
          info='Enter the desired criteria and click the Generate Report button.'
        />
        <div style={{ padding: '20px'}} className='widget'>
          
          <Form className="d-flex justify-content-center" ref='settingsForm' onSubmit={this.submitHandler}>

            <FormGroup style={{ maxWidth: '200px' }} controlId="month">
              <ControlLabel>Month: </ControlLabel>
              <FormControl style={{fontFamily:'monospace'}} componentClass="select" placeholder="select" defaultValue={this.state.period} onChange={this.handleChange_period}>
                {
                  this.dates.map((date, index) => {
                    return (<option key={index} value={this.getPeriodValue(date)}>{this.getPeriodDisplayStr(date)}</option>)
                  })
                }
              </FormControl>
            </FormGroup>

          </Form>

        </div>

        <Button
          style={{ marginTop: '20px', marginRight: '15px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Cancel
        </Button>
        <Button
          style={{ marginTop: '20px' }}
          onClick={() => {
            this.handleClick_reportButton();
          }}
          alt='...'
        >
          Generate Report
        </Button>

        <LoadingDialog ref='loadingDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default ActivityReportCriteria;
