import { API } from 'aws-amplify';
import React from 'react';
import { Button,ControlLabel,Form,FormControl,FormGroup } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ConfirmDialog from './ConfirmDialog';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './AddCustomer.css';

/*
const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

const validateInput = (value) => {
  let error = '';

  if (!value) error = 'Required!';
  else if (value.length !== 14) error = 'Invalid phone format. ex: (555) 555-5555';

  return error;
};
*/

class AddCustomer extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.settings = {};

    this.state = {
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
      fileName: '',
    };

    // EVENT HANDLERS

    // onChange
    this.handleChange_companyName = this.handleChange_companyName.bind(this);
    this.handleChange_firstName = this.handleChange_firstName.bind(this);
    this.handleChange_lastName = this.handleChange_lastName.bind(this);
    this.handleChange_email = this.handleChange_email.bind(this);
    this.handleChange_confirmEmail = this.handleChange_confirmEmail.bind(this);
    this.handleChange_password = this.handleChange_password.bind(this);
    this.handleChange_fileName = this.handleChange_fileName.bind(this);

    // onClick
    this.handleClick_cancelButton = this.handleClick_cancelButton.bind(this);
    this.handleClick_addButton = this.handleClick_addButton.bind(this);
  }

  // EVENT HANDLERS

  // onChange
  handleChange_companyName(e) {
    this.setState({ companyName: e.target.value });
  }

  handleChange_firstName(e) {
    this.setState({ firstName: e.target.value });
  }

  handleChange_lastName(e) {
    this.setState({ lastName: e.target.value });
  }

  handleChange_email(e) {
    this.setState({ email: e.target.value });
  }

  handleChange_confirmEmail(e) {
    this.setState({ confirmEmail: e.target.value });
  }

  handleChange_password(e) {
    this.setState({ password: e.target.value });
  }

  handleChange_fileName(e) {
    this.setState({ fileName: e.target.value });
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
/*      
      this.settings = await this.getSettings();
      if (!this.settings['timezone']) {
        this.settings['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      this.setState({ identifier: this.settings['identifier'] });
//      this.setState({ time_str: this.settings['time'] });
      this.setState({ timezone: this.settings['timezone'] });
      if (this.settings['mobile_number']) {
        this.setState({
          notification_method: 'mobile',
          mobile_number_server: this.settings['mobile_number'],
          mobile_number: this.getGuiFormattedPhoneNumber(this.settings['mobile_number']),
          mobile_number_verified: true,
        });
      }
      if (this.settings['email']) {
        this.setState({
          notification_method: 'email',
          email_server: this.settings['email'],
          email: this.settings['email'],
          email_verified: true,
        });
      }

      this.forceUpdate();
*/      
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  handleClick_cancelButton() {
    this.props.history.push('/customers');
  }

  validateFields() {
    if (this.state.email != this.state.confirmEmail) {
      throw new Error('Email and Confirm Email do not match.');
    }
  }

  addCustomer() {
    return API.post('cloudautocheckissues', '/adminUser', {
      body: {
        company_name: this.state.companyName,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        file_name: this.state.fileName,
        resend: false,
      },
    });
  }

  async handleClick_addButton()
  {
    try
    {
      this.setState({ companyName: this.state.companyName.trim(), firstName: this.state.firstName.trim(), lastName: this.state.lastName.trim(), email: this.state.email.trim() });

      this.validateFields();

      var reply = await this.addCustomer();
      if (reply.error)
      {
        this.refs.errorDialog.displayError('Error', reply.error);
      }
      else
      {
        this.props.history.push('/customers');
      }      
    }
    catch (e)
    {
      this.refs.errorDialog.displayError('Error', e.message);
    }
    finally
    {
    }
  }

  render() {
    return (
      <div className='AddUser'>
        <PageHeader
          title='Add User'
          info='The system will send a Welcome email to the specified email address with their User Name and temporary password.'
        />
        <div style={{ padding: '20px'}} className='widget'>
          
          <Form class="d-flex justify-content-center debug" ref='settingsForm' onSubmit={this.submitHandler}>

            <FormGroup
              validationState={this.state.email_verified ? 'success' : null}
              style={{ maxWidth: '450px' }}
              controlId='email'
            >
              <ControlLabel>Email:</ControlLabel>
              <FormControl
                type='text'
                ref='email'
                value={this.state.email}
                onChange={this.handleChange_email}
              />
            </FormGroup>

            <FormGroup
              style={{ maxWidth: '450px' }}
              controlId='confirmEmail'
            >
              <ControlLabel>Confirm Email:</ControlLabel>
              <FormControl
                type='text'
                ref='email'
                value={this.state.confirmEmail}
                onChange={this.handleChange_confirmEmail}
              />
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='companyName'>
              <ControlLabel>Company Name:</ControlLabel>
              <FormControl type="text" placeholder="" onChange={this.handleChange_companyName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='firstName'>
              <ControlLabel>First Name:</ControlLabel>
              <FormControl type="text" placeholder="" onChange={this.handleChange_firstName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='lastName'>
              <ControlLabel>Last Name:</ControlLabel>
              <FormControl type="text" placeholder="" onChange={this.handleChange_lastName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px'}} controlId='password'>
              <ControlLabel>Temporary Password:</ControlLabel>
              <ControlLabel style={{fontStyle: 'italic' }}>(If left blank, password will be automatically created.)</ControlLabel>
              <FormControl type='password' placeholder='' onChange={this.handleChange_password} />
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px'}} controlId='fileName'>
              <ControlLabel>File Name:</ControlLabel>
              <FormControl type='text' placeholder='' onChange={this.handleChange_fileName} />
            </FormGroup>

          </Form>
        </div>

        <Button
          style={{ marginTop: '20px', marginRight: '15px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Cancel
        </Button>

        <Button
          style={{ marginTop: '20px' }}
          disabled={!((this.state.companyName.trim().length > 0) && (this.state.firstName.trim().length > 0) && (this.state.lastName.trim().length > 0) && (this.state.email.trim().length > 0))}
          onClick={() => {
            this.handleClick_addButton();
          }}
          alt='...'
        >
          Add
        </Button>
     
        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default AddCustomer;
