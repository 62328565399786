import React, { useState }  from 'react';
import { Button,ControlLabel,Form,FormGroup } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './AuditReportCriteria.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AuditReportCriteria extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.settings = {};

    this.state = {
      user_id: props.location.user_id,
      begin_date: new Date(),
      end_date: new Date(),
    };

    // EVENT HANDLERS
    // onClick
    this.handleClick_cancelButton = this.handleClick_cancelButton.bind(this);
    this.handleClick_reportButton = this.handleClick_reportButton.bind(this);
  }

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  handleClick_cancelButton() {
    this.props.history.push('/customers');
  }

  async handleClick_reportButton()
  {
    try
    {
      if (this.state.begin_date == null)
      {
        throw new Error("From Date cannot be blank.")
      }
      if (this.state.end_date == null)
      {
        throw new Error("To Date cannot be blank.")
      }
      if (this.state.begin_date > this.state.end_date)
      {
        throw new Error ("From Date must be before To Date.")
      }
      this.props.history.push({pathname: "/auditreport", report_criteria: { "user_id": this.state.user_id, begin_date: this.state.begin_date.toString('yyyy-MM-dd'), end_date: this.state.end_date.toString('yyyy-MM-dd'), timezone: Intl.DateTimeFormat().resolvedOptions().timeZone} });
    }
    catch (e)
    {
      this.refs.errorDialog.displayError('Error', e.message);
    }
    finally
    {
    }
  }

  render() {
    return (
      <div className='AuditReportCriteria'>
        <PageHeader
          title='Audit Report Criteria'
          info='Enter the desired criteria and click the Generate Report button.'
        />
        <div style={{ padding: '20px'}} className='widget'>
          
          <Form className="d-flex justify-content-center" ref='settingsForm' onSubmit={this.submitHandler}>
            <FormGroup style={{ maxWidth: '250px' }} controlId='dateFrom'>
              <ControlLabel>From:</ControlLabel>
              <DatePicker selected={this.state.begin_date} onChange={(date) => this.setState({begin_date:date})} />
            </FormGroup>

            <FormGroup style={{ maxWidth: '250px' }} controlId='dateTo'>
              <ControlLabel>To:</ControlLabel>
              <DatePicker selected={this.state.end_date} onChange={(date) => this.setState({end_date:date})} />
            </FormGroup>
          </Form>

        </div>

        <Button
          style={{ marginTop: '20px', marginRight: '15px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Cancel
        </Button>
        <Button
          style={{ marginTop: '20px' }}
          onClick={() => {
            this.handleClick_reportButton();
          }}
          alt='...'
        >
          Generate Report
        </Button>

        <LoadingDialog ref='loadingDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}

export default AuditReportCriteria;
