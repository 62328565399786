import { API } from 'aws-amplify';
import React from 'react';
import { Button,ControlLabel,Form,FormControl,FormGroup } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import TimeoutComponent from '../components/TimeoutComponent';
import '../css/branding.css';
import ConfirmDialog from './ConfirmDialog'; // Import
import ErrorDialog from './ErrorDialog';
import LoadingDialog from './LoadingDialog';
import './EditCustomer.css';

class EditCustomer extends TimeoutComponent {
  constructor(props) {
    super(props);

    debugger;
    
    this.settings = {};

    this.state = {
      companyName: props.location.user.company_name,
      firstName: props.location.user.first_name,
      lastName: props.location.user.last_name,
      email: props.location.user.email,
      fileName: props.location.user.file_name,
    };

    // EVENT HANDLERS

    // onChange
    this.handleChange_companyName = this.handleChange_companyName.bind(this);
    this.handleChange_firstName = this.handleChange_firstName.bind(this);
    this.handleChange_lastName = this.handleChange_lastName.bind(this);
//    this.handleChange_email = this.handleChange_email.bind(this);
    this.handleChange_fileName = this.handleChange_fileName.bind(this);

    // onClick
    this.handleClick_cancelButton = this.handleClick_cancelButton.bind(this);
    this.handleClick_saveButton = this.handleClick_saveButton.bind(this);;
  }

  // EVENT HANDLERS

  // onChange
  handleChange_companyName(e) {
    this.setState({ companyName: e.target.value });
  }

  handleChange_firstName(e) {
    this.setState({ firstName: e.target.value });
  }

  handleChange_lastName(e) {
    this.setState({ lastName: e.target.value });
  }

//  handleChange_email(e) {
//    this.setState({ email: e.target.value });
//  }

  handleChange_fileName(e) {
    this.setState({ fileName: e.target.value });
  }

  // Hidden field to store file.sequence_num.

  async componentDidMount() {
    try {
      this.refs.loadingDialog.show(true);   
    } catch (e) {
      this.refs.errorDialog.displayError('Error', e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  handleClick_cancelButton() {
    this.props.history.push('/customers');
  }

  validateFields() {
  }

  updateCustomer() {
    return API.put('cloudautocheckissues', '/adminUser', {
      body: {
        company_name: this.state.companyName,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        file_name: this.state.fileName,
        resend: false,
      },
    });
  }

  async handleClick_saveButton()
  {
    try
    {
      this.setState({ companyName: this.state.companyName.trim(), firstName: this.state.firstName.trim(), lastName: this.state.lastName.trim(), email: this.state.email.trim() });

      this.validateFields();

      var reply = await this.updateCustomer();
      if (reply.error)
      {
        this.refs.errorDialog.displayError('Error', reply.error);
      }
      else
      {
        this.props.history.push('/customers');
      }      
    }
    catch (e)
    {
      this.refs.errorDialog.displayError('Error', e.message);
    }
    finally
    {
    }
  }

  render() {
    return (
      <div className='EditUser'>
        <PageHeader
          title='Edit User'
          info=''
        />
        <div style={{ padding: '20px'}} className='widget'>
          
          <Form class="d-flex justify-content-center debug" ref='settingsForm' onSubmit={this.submitHandler}>

            <FormGroup
              validationState={this.state.email_verified ? 'success' : null}
              style={{ maxWidth: '450px' }}
              controlId='email'
            >
              <ControlLabel>Email: {this.state.email}</ControlLabel>
{/*
              <FormControl
                type='text'
                ref='email'
                value={this.state.email}
                onChange={this.handleChange_email}
              />
*/}
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='companyName'>
              <ControlLabel>Company Name:</ControlLabel>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.companyName}
                onChange={this.handleChange_companyName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='firstName'>
              <ControlLabel>First Name:</ControlLabel>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.firstName}
                onChange={this.handleChange_firstName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px' }} controlId='lastName'>
              <ControlLabel>Last Name:</ControlLabel>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.lastName}
                onChange={this.handleChange_lastName}/>
            </FormGroup>

            <FormGroup style={{ maxWidth: '450px'}} controlId='fileName'>
              <ControlLabel>File Name:</ControlLabel>
              <FormControl
                type='text'
                placeholder=''
                value={this.state.fileName}
                onChange={this.handleChange_fileName} />
            </FormGroup>

          </Form>
        </div>

        <Button
          style={{ marginTop: '20px', marginRight: '15px' }}
          onClick={() => {
            this.handleClick_cancelButton();
          }}
          alt='...'
        >
          Cancel
        </Button>
        <Button
          style={{ marginTop: '20px' }}
          disabled={!((this.state.companyName.trim().length > 0) && (this.state.firstName.trim().length > 0) && (this.state.lastName.trim().length > 0) && (this.state.email.trim().length > 0))}
          onClick={() => {
            this.handleClick_saveButton();
          }}
          alt='...'
        >
          Save
        </Button>
       
        <LoadingDialog ref='loadingDialog' />
        <ConfirmDialog ref='confirmDialog' />
        <ErrorDialog ref='errorDialog' />
      </div>
    );
  }
}


export default EditCustomer;
