import React from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./ForgotPassword.css";
import { Button } from "react-bootstrap"
import ErrorDialog from './ErrorDialog'
import PageHeader from "../components/PageHeader"

class ForgotPasswordConfirm extends React.Component {

  constructor(props)
  {
      super(props);
      this.handleChange_confirmation_code = this.handleChange_confirmation_code.bind(this);
      this.handleChange_password = this.handleChange_password.bind(this);
      this.handleChange_confirm_password = this.handleChange_confirm_password.bind(this);

      this.state =
      {
          confirmation_code: "",
          password: "",
          confirm_password: ""
      };
  }

  async componentDidMount()
  {
  }

  handleChange_confirmation_code(e) {
    this.setState({ confirmation_code: e.target.value });
  }

  handleChange_password(e) {
    this.setState({ password: e.target.value });
  }

  handleChange_confirm_password(e) {
    this.setState({ confirm_password: e.target.value });
  }

  isBlank(str) {
      return (!str || /^\s*$/.test(str));
  }

  async onClick_ForgotPasswordConfirm(event)
  {
    try {
      if (this.isBlank(this.state.confirmation_code)){
        this.refs.errorDialog.displayError("Confirmation Code","Confirmation Code must be entered.")
      }else if( this.isBlank(this.state.password)){
        this.refs.errorDialog.displayError("Password","Password must be entered.")
      }else if (this.isBlank(this.state.confirm_password)){
        this.refs.errorDialog.displayError("Confirm Password","Confirm Password must be entered.")
      }else if (this.state.password !== this.state.confirm_password){
        this.refs.errorDialog.displayError("Password Mismatch","Password and Confirm Password must match.")
      }else{
        await Auth.forgotPasswordSubmit(this.props.location.username,this.state.confirmation_code, this.state.password)
        this.props.history.push("/login")
      }

    } catch (e) {
      this.refs.errorDialog.displayError("Error", e.message)
    } finally {

    }
  }


  render() {
    return (
      <div className="ResetPassword">
        <PageHeader title="Reset password" info=""/>
        <div style={{padding:'30px'}} className="widget">
          <form name='settingsForm' autocomplete="off">
            <FormGroup style={{marginBottom:'30px'}} controlId="confirmationccode" bsSize="large">
                <ControlLabel>Verification Code:</ControlLabel>
                <FormControl type="text" placeholder=""  onChange={this.handleChange_confirmation_code}/>
            </FormGroup>
            <FormGroup style={{marginBottom:'30px'}} controlId="password" bsSize="large">
                <ControlLabel>Password:</ControlLabel>
                <FormControl type="password" placeholder=""  onChange={this.handleChange_password}/>
            </FormGroup>
            <FormGroup style={{marginBottom:'30px'}} controlId="confirmpassword" bsSize="large">
                <ControlLabel>Confirm Password:</ControlLabel>
                <FormControl type="password" placeholder=""  onChange={this.handleChange_confirm_password}/>
            </FormGroup>
            <Button style={{marginTop:'20px', width:'100%'}} bsSize="large" onClick={() => {this.onClick_ForgotPasswordConfirm()}} alt="...">Submit</Button>
          </form>
      </div>
      <ErrorDialog ref="errorDialog"/>
      </div>
    );
  }
}

export default ForgotPasswordConfirm;
